import { Avatar } from "antd";
import { func } from "prop-types";
import { Folder, Graph, Home } from "react-iconly";
// import PDC1 from '../../../../assets/CustomeIcon/PDC-App-76x76@1x.png'
// import PDC from '../../../../assets/CustomeIcon/pdc.svg'
// import {ReactComponent  as Carbspng} from '../../../../assets/CustomeIcon/Carbs.png'
// import PDC_2 from '../../../../assets/CustomeIcon/PDC-App-76x76@2x.png'
// import NTLogo from '../../../../assets/CustomeIcon/Neurotouch_Logo.png'
// import HCLogo from '../../../../assets/CustomeIcon/Heart care logo.png'

function MenuIcon(props) {
    if (props.name === "Home") {
        return (<Home set="curved" className="remix-icon" />)
    }
    else if (props.name === "Graph") {
        return (<Graph set="curved" className="remix-icon" />)
    }
    else if (props.name === "Folder") {
        return (<Folder set="curved" className="remix-icon" />)
    }
    // else if (props.name === "PDC") {
    //     return( <Avatar src={PDC_2} size={30} set="curved" className="remix-icon"/>);
    // }
    else {
        return (<></>)
    }



}

export default MenuIcon;
