import { lazy } from "react";

const PagesRoutes = [
  {
    path: "/login",
    component: lazy(() => import("../../pages/authentication/login")),
    layout: "FullLayout",
    pageType: "authentication",
  },
  {
    path: "/",
    exact: true,
    component: lazy(() => import("../../pages/dashboard")),
    layout: "VerticalLayout",
    pageType: "private",
  },
  {
    path: "/subjectlist",
    exact: true,
    component: lazy(() => import("../../pages/patient")),
    layout: "VerticalLayout",
    pageType: "private",
  },
  {
    path: "/addsubject",
    exact: true,
    component: lazy(() => import("../../pages/patient/addpatient")),
    layout: "VerticalLayout",
    pageType: "private",
  },
  {
    path: "/addIDSsubject",
    exact: true,
    component: lazy(() => import("../../pages/patient/IDSAdapt/addpatient")),
    layout: "VerticalLayout",
    pageType: "private",
  },
  {
    path: "/employeelist",
    exact: true,
    component: lazy(() => import("../../pages/empwisesubject")),
    layout: "VerticalLayout",
    pageType: "private",
  },
  {
    path: "/userList",
    exact: true,
    component: lazy(() => import("../../pages/UserManagement")),
    layout: "VerticalLayout",
    pageType: "private",
  },
];

export default PagesRoutes;
