import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
export function TextUpperCase(a) {
    return a.toUpperCase();
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function sessionStoragegetItem(Key) {
    return sessionStorage.getItem(Key);
}

export function StringtoIntConvert(string) {
    if (string != undefined && string != "")
        return parseInt(string, 10);
    else
        return 0
}

export function StringtoDecimalConvert(string) {
    if (string != undefined && string != "")
        return parseFloat(string, 10);
    else
        return 0
}


export function BmiCalculator(weight, height) {
    if (weight != undefined && weight != "" && height != undefined && height != "") {
        let bmi = (weight / height / height) * 10000;
        return bmi.toFixed(2);;
    } else
        return 0
}

export function CheckMaxLimit(value, MaxLimit) {
    if (value == undefined || value == "")
        return false
    else {
        if (value <= MaxLimit)
            return false
        else
            return true
    }
}
export function CheckMinMaxLimit(value, MinLimit,MaxLimit) {
    if (value > MinLimit && value <= MaxLimit)
        return false
    else
        return true

}

export function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  
export function ExportToExcel(apidata, filename) {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
  
    const ws = XLSX.utils.json_to_sheet(apidata);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
  
    FileSaver.saveAs(data, filename + fileExtension);
  }
