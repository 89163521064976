export const version = `api/${process.env.REACT_APP_VERSION}/`;
export const loginUrl = version + 'Accounts/Login';
export const getmenuUrl = version + 'Menu/getmenu';
export const getmasterdataUrl = version + 'MasterData/GetMasterData';
export const getSubjectDataByEmployeeIdUrl = version + 'Patient/GetSubjectDataByEmployeeId';
export const saveDemographicsUrl = version + 'Patient/SaveDemographics';
export const deleteDemographicsUrl = version + 'Patient/DeleteDemographics';
export const getDemographicDetailsBySubjectIdUrl = version + 'Patient/GetDemographicDetailsBySubjectId?SubjectId=';
export const getLifeStyleFactorsBySubjectIdUrl = version + 'Patient/GetLifeStyleFactorsBySubjectId?SubjectId=';
export const getConcomitantMedicationsBySubjectIdUrl = version + 'Patient/GetConcomitantMedicationsBySubjectId?SubjectId=';
export const saveLifeStyleFactorsUrl = version + 'Patient/SaveLifeStyleFactors';
export const saveConcomitantMedicationsUrl = version + 'Patient/SaveConcomitantMedications';
export const saveMedicalConditionsUrl = version + 'Patient/SaveMedicalConditions';
export const getMedicalConditionBySubjectIdUrl = version + 'Patient/GetMedicalConditionBySubjectId?SubjectId=';
export const saveMedicationDetailsUrl = version + 'Patient/SaveMedicationDetails';
export const getMedicationDetailsBySubjectIdUrl = version + 'Patient/GetMedicationDetailsBySubjectId?SubjectId=';
export const saveInvestigationsUrl = version + 'Patient/SaveInvestigations';
export const getInvestigationBySubjectIdUrl = version + 'Patient/GetInvestigationBySubjectId?SubjectId=';
export const getSubjectDataForAdminUrl = version + 'Patient/GetSubjectDataForAdmin';
export const getSubjectDataListforExcelUrl = version + 'Patient/GetSubjectDataListforExcel';
export const prescriptionImageUploadUrl = version + 'Patient/PrescriptionImageUpload';
export const savePersonalHabitHistory = version + 'Patient/SavePersonalHabitHistory';
export const getPersonalHabitHistory = version + 'Patient/GetPersonalHabitHistory?SubjectId=';




export const registerBulkUserUrl = version + 'Accounts/RegisterBulkUser';
export const registerUserUrl = version + 'Accounts/RegisterUser';
export const getUserListUrl = version + 'Users/GetUserList';
export const UploadFileUrl = version + 'Patient/UploadFile';