import { Graph } from "react-iconly";
import { BsListUl,BsListTask } from "react-icons/bs";

const pages = [
  // {
  //   header: "PAGES",
  // },
  {
    id: "subjectlist",
    icon: <Graph set="curved" className="remix-icon" />,
    title: "Subject List",
    navLink: "/subjectlist",
    role:'All',
  },
  {
    id: "employeelist",
    icon: <Graph set="curved" className="remix-icon" />,
    title: "Employee List",
    navLink: "/employeelist",
    role:'Admin'
  },
  {
    id: "userList",
    icon: <BsListUl set="curved" className="remix-icon" />,
    title: "User Management",
    navLink: "/userList",
    role: 'Admin',
  },
  // {
  //   id: "all-reports",
  //   title: "Reports",
  //   icon: <Graph set="curved" className="remix-icon" />,
  //   children: [
  //     {
  //       id: "blank-page",
  //       title: "Blank Page",
  //       navLink: "/pages/blank-page",
  //     },
  //   ],
  // },
];

export default pages;
