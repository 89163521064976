import { Menu } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { APIService } from "../../../../common/apiService";
import { getmenuUrl } from "../../../../common/apiUrl";
import { sessionStoragegetItem } from "../../../../common/Helpers";
import Loader from "../../../../common/loader";
import navigation from "../../../../navigation/vertical";
import MenuIcon from "./menuIcon";

const { SubMenu } = Menu;

const MenuItem = (props) => {
  const { onClose } = props;
  const [sidemenuItem, setSideMenuItem] = useState([]);
  const [listDataLoad, setlistDataLoad] = useState(true);

  // Location
  const location = useLocation();
  const { pathname } = location;

  const splitLocation = pathname.split("/");

  // Menu
  const splitLocationUrl =
    splitLocation[splitLocation.length - 2] +
    "/" +
    splitLocation[splitLocation.length - 1];

  const getMenu = () => {
    
    setlistDataLoad(true);
    let Tempmenu = navigation;
    
    let UserType = sessionStoragegetItem("userType");
    
    if(UserType == 2)
    {
      setSideMenuItem(Tempmenu.filter(d => d.role === "All"));
    }
    else
    {
      setSideMenuItem(Tempmenu.filter(d => d.role === "Admin"));
    }
    setlistDataLoad(false);
    // APIService.get(getmenuUrl)
    //   .then(res => {
    //     setlistDataLoad(false);
    //     if (!res.data.isError) {
    //       setSideMenuItem(res.data.data);
    //       if (res.data.data.length == 0) {
    //         Swal.fire("You Don't have any Menu Item. Please Contact Data Administrator ", '', 'info');
    //       }
    //     } else {
    //       Swal.fire(res.data.message, '', 'error');
    //     }
    //   }).catch(error => {
    //     setlistDataLoad(false);
    //     console.log("error", error)
    //   });
  }

    const menuItem = sidemenuItem.map((item, index) => {
    if (item.header) {
      return <Menu.ItemGroup key={index} title={item.header}></Menu.ItemGroup>;
    }

    if (item.children) {
      return (
        <SubMenu key={item.id} icon={<MenuIcon name={item.icon} />} title={item.title}>
          {/* <SubMenu key={item.id} icon={ <i className={item.icon} />} title={item.title}> */}
          {/* <SubMenu key={item.id} icon={item.icon} title={item.title}> */}
          {item.children.map((childrens, index) => {
            if (!childrens.children) {
              const childrenNavLink = childrens.navLink.split("/");

              return (
                // Level 2
                <Menu.Item
                  key={childrens.id}
                  className={
                    splitLocationUrl ===
                      childrenNavLink[childrenNavLink.length - 2] +
                      "/" +
                      childrenNavLink[childrenNavLink.length - 1]
                      ? "ant-menu-item-selected"
                      : "ant-menu-item-selected-in-active"
                  }
                  onClick={onClose}
                >
                  <Link to={childrens.navLink}>{childrens.title}</Link>
                </Menu.Item>
              );
            } else {
              return (
                // Level 3
                <SubMenu key={childrens.id} icon={<MenuIcon name={childrens.icon} />} title={childrens.title}>
                  {childrens.children.map((childItem, index) => {
                    const childrenItemLink = childItem.navLink.split("/");

                    return (
                      <Menu.Item
                        key={childItem.id}
                        className={
                          splitLocationUrl ===
                            childrenItemLink[childrenItemLink.length - 2] +
                            "/" +
                            childrenItemLink[childrenItemLink.length - 1]
                            ? "ant-menu-item-selected"
                            : "ant-menu-item-selected-in-active"
                        }
                        onClick={onClose}
                      >
                        <Link to={childItem.navLink}>{childItem.title}</Link>
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            }
          })}
        </SubMenu>
      );
    } else {
      const itemNavLink = item.navLink.split("/");

      return (
        // Level 1
        <Menu.Item
          key={item.id}
          icon={item.icon}
          onClick={onClose}
          className={
            splitLocation[splitLocation.length - 2] +
              "/" +
              splitLocation[splitLocation.length - 1] ===
              itemNavLink[itemNavLink.length - 2] +
              "/" +
              itemNavLink[itemNavLink.length - 1]
              ? "ant-menu-item-selected"
              : "ant-menu-item-selected-in-active"
          }
        >
          <Link to={item.navLink}>{item.title}</Link>
        </Menu.Item>
      );
    }
  });

  const menuItemTemp = sidemenuItem.map((item, index) => {
    if (item.header) {
      return <Menu.ItemGroup key={index} title={item.header}></Menu.ItemGroup>;
    }

    if (item.children) {
      return (
        <SubMenu key={item.id} icon={<MenuIcon name={item.icon} />} title={item.title}>
          {/* <SubMenu key={item.id} icon={ <i className={item.icon} />} title={item.title}> */}
          {/* <SubMenu key={item.id} icon={item.icon} title={item.title}> */}
          {item.children.map((childrens, index) => {
            if (!childrens.children) {
              const childrenNavLink = childrens.navLink.split("/");

              return (
                // Level 2
                <Menu.Item
                  key={childrens.id}
                  className={
                    splitLocationUrl ===
                      childrenNavLink[childrenNavLink.length - 2] +
                      "/" +
                      childrenNavLink[childrenNavLink.length - 1]
                      ? "ant-menu-item-selected"
                      : "ant-menu-item-selected-in-active"
                  }
                  onClick={onClose}
                >
                  <Link to={childrens.navLink}>{childrens.title}</Link>
                </Menu.Item>
              );
            } else {
              return (
                // Level 3
                <SubMenu key={childrens.id} icon={<MenuIcon name={childrens.icon} />} title={childrens.title}>
                  {childrens.children.map((childItem, index) => {
                    const childrenItemLink = childItem.navLink.split("/");

                    return (
                      <Menu.Item
                        key={childItem.id}
                        className={
                          splitLocationUrl ===
                            childrenItemLink[childrenItemLink.length - 2] +
                            "/" +
                            childrenItemLink[childrenItemLink.length - 1]
                            ? "ant-menu-item-selected"
                            : "ant-menu-item-selected-in-active"
                        }
                        onClick={onClose}
                      >
                        <Link to={childItem.navLink}>{childItem.title}</Link>
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            }
          })}
        </SubMenu>
      );
    } else {
      const itemNavLink = item.navLink.split("/");

      return (
        // Level 1
        <Menu.Item
          key={item.id}
          icon={item.icon}
          onClick={onClose}
          className={
            splitLocation[splitLocation.length - 2] +
              "/" +
              splitLocation[splitLocation.length - 1] ===
              itemNavLink[itemNavLink.length - 2] +
              "/" +
              itemNavLink[itemNavLink.length - 1]
              ? "ant-menu-item-selected"
              : "ant-menu-item-selected-in-active"
          }
        >
          <Link to={item.navLink}>{item.title}</Link>
        </Menu.Item>
      );
    }
  });

  useEffect(() => {
    getMenu();
  }, []);

  return (
    <>
      {listDataLoad
        ?
        <Loader />
        :
        <Menu
          mode="inline"
          defaultOpenKeys={[
            splitLocation.length === 5
              ? splitLocation[splitLocation.length - 3]
              : null,
            splitLocation[splitLocation.length - 2],
          ]}
          theme="light"
        >
          {/* {menuItem} */}
          {menuItemTemp}
        </Menu>
      }
    </>
  );
};

export default MenuItem;
