import { Avatar, Col, Dropdown, Menu } from "antd";
import { Logout } from "react-iconly";
import { useHistory } from "react-router-dom";
import avatarImg from "../../../assets/images/memoji/Circle-icons-profile.svg.png";
import { sessionStoragegetItem } from "../../../common/Helpers";

const HeaderUser = () => {
  const history = useHistory();
  const menu = (
    <Menu theme="light">

<Menu.ItemGroup
        key={0}
        title={sessionStoragegetItem("fullName")}
        className="headerDisplayName" >
      </Menu.ItemGroup>

      <Menu.Divider>

      </Menu.Divider>

      <Menu.Item
        onClick={() => {
          sessionStorage.clear();
          localStorage.removeItem('token');
          history.push("/login");
        }}
        key={1}
        icon={
          <Logout
            set="curved"
            className="remix-icon da-vertical-align-middle da-text-color-dark-0"
            size={16}
          />
        }
        className="da-text-color-dark-0"
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Col>
      <Dropdown overlay={menu} placement="bottomLeft">
        <Col className="da-d-flex-center" onClick={(e) => e.preventDefault()}>
          <Avatar src={avatarImg} size={40} />
        </Col>
      </Dropdown>
    </Col>
  );
};

export default HeaderUser;
