import { Col, Layout, Row } from "antd";
import React, { useState } from "react";
import MenuFooter from "./components/footer";
import MenuHeader from "./components/header";
import Sidebar from "./components/menu/Sidebar";

const { Content } = Layout;

const VerticalLayout = ({ children }) => {
  const [visible, setVisible] = useState(false);

  return (
    <Layout className="da-app-layout">
      <Sidebar visible={visible} setVisible={setVisible} />

      <Layout className="da-bg-color-dark-90">
        <MenuHeader setVisible={setVisible} />

        <Content className="da-content-main">
          <Row justify="center">
            <Col span={24}>{children}</Col>
          </Row>
        </Content>

        <MenuFooter />
      </Layout>
    </Layout>
  );
};

export default VerticalLayout;
