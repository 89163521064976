import { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { APIService } from "../common/apiService";
import { pageauthorizationUrl } from "../common/apiUrl";
import FullLayout from "../layout/FullLayout";
import VerticalLayout from "../layout/VerticalLayout";
import Error404 from "../pages/error";
import Error403 from "../pages/error/error403";
import { Routes } from "./routes";

const Router = () => {
  // LTR
  useEffect(() => {
    document.querySelector("html").setAttribute("dir", "ltr");
  }, []);

  // All of the available layouts
  const Layouts = { VerticalLayout, FullLayout };
  const [isPageAuthorization, setisPageAuthorization] = useState(true);

  const PageAuthorization = (e) => {
    // if (e.path === "/") {
      setisPageAuthorization(true);
    // }
    // else {
    //   let path=e.path;

    //   if (e.path === "/usermenumapping") {
    //     path='/useraccess';
    //   }

    //   APIService.post(pageauthorizationUrl + path)
    //     .then(res => {
    //       if (!res.data.isError) {
    //         setisPageAuthorization(res.data.data);
    //       } else {
    //         setisPageAuthorization(false);
    //       }
    //     }).catch(error => {
    //       setisPageAuthorization(false)
    //     });
    // }
    // // return true;
  }

  return (
    <BrowserRouter>
      <Switch>
        {/* Auth Guarded Routes */}
        {Routes.map((route) => {
          let RouteLayout = Layouts[route.layout];
          if (route.pageType === "private") {
            return (
              <Route
                path={route.path}
                exact={route.exact === true}
                render={(props) => {
                  let isUserAuthenticated =
                    !!sessionStorage.token || !!localStorage.token;
                  if (isUserAuthenticated) {
                    PageAuthorization(route);
                    if (isPageAuthorization) {
                      return (
                        <Suspense fallback={null}>
                          <RouteLayout>
                            <route.component {...props} />
                          </RouteLayout>
                        </Suspense>
                      );
                    }
                    else {
                      return <Redirect to="/forbidden" />;
                    }
                  } else {
                    return <Redirect to="/login" />;
                  }
                }}
              />
            );
          } else if (route.pageType === "authentication") {
            return (
              <Route
                path={route.path}
                exact={route.exact === true}
                render={(props) => {
                  let isUserAuthenticated =
                    !!sessionStorage.token || !!localStorage.token;
                  if (!isUserAuthenticated) {
                    return (
                      <Suspense fallback={null}>
                        <RouteLayout>
                          <route.component {...props} />
                        </RouteLayout>
                      </Suspense>
                    );
                  } else {
                    return <Redirect to="/" />;
                  }
                }}
              />
            );
          } else {
            return (
              <Route
                path={route.path}
                exact={route.exact === true}
                render={(props) => {
                  return (
                    <Suspense fallback={null}>
                      <RouteLayout>
                        <route.component {...props} />
                      </RouteLayout>
                    </Suspense>
                  );
                }}
              />
            );
          }
        })}
        <Route path="/forbidden">
          <Error403 />
        </Route>
        <Route path="*">
          <Error404 />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
